import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { useLocation } from 'react-router-dom'
import useHasMounted from '../../lib/useHasMounted'
import { adSlotRegistered as handleAdSlotRegistered } from '../../store/app'
import IonAd from 'ion-ads'

const Ad = (props) => {
  const location = useLocation()
  const [path, setPath] = useState(location.pathname)
  const pathname = location.pathname
  const hasHydrated = useHasMounted()
  useEffect(() => {
    if (location.pathname !== path) {
      setPath(location.pathname)
    }
  }, [pathname])
  if (path !== pathname || !hasHydrated) {
    return null
  }
  return (
    <IonAd
      className={props.className}
      isConnected={props.isConnected}
      path={`/${process.env.RAZZLE_DFP_CODE}${props.adPath || (path === '/' ? '/homepage' : path)}`}
      slotId={props.slotId} targeting={props.targeting}
      onSlotRenderEnded={props.onSlotRenderEnded}
      collapseEmptyDiv={props.collapseEmptyDiv} {...props}
      onAdSlotRegistered={props.handleAdSlotRegistered}
    />
  )
}

const mapDispatchToProps = (dispatch) => bindActionCreators({ handleAdSlotRegistered }, dispatch)

export default connect((state) => ({ app: state.app }), mapDispatchToProps)(Ad)
